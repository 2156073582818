<template>
  <AtomButtonDropdown
    :class="classes"
    :auto-width="autoWidth"
    :items="items"
    :initial-index="initialCity"
    @select="onCitySelect"
  />
</template>

<script>
import AtomButtonDropdown from '../../atoms/AtomButtonDropdown'

export default {
  name: 'TownsDropdown',
  components: {
    AtomButtonDropdown,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    withQuery: {
      type: String,
      default: 'city',
    },
    classes: {
      type: String,
      default: 'w-full md:w-[70%] md:max-w-[640px]',
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    initialCity() {
      if (this.withQuery) {
        const initial = this.items.findIndex(({ cityId }) => cityId == this.$route.query[this.withQuery])
        return initial === -1 ? this.getByGeoposition() : initial
      }
      return this.getByGeoposition()
    },
  },
  beforeMount() {
    const city = this.items[this.initialCity]
    this.$emit('select', city.cityId)
  },
  methods: {
    onCitySelect(i) {
      const city = this.items[i]
      if (this.withQuery) {
        this.$router.push({ query: { [this.withQuery]: city.cityId } })
      }
      this.$emit('select', city.cityId)
    },
    getByGeoposition() {
      const geo = this.$store.getters['modules/content/geoposition']
      if (!geo.id) return 0
      const index = this.items.findIndex(({ cityId }) => cityId === geo.id)
      return index === -1 ? 0 : index
    },
  },
}
</script>
