<template>
  <div class="-md:hidden">
    <AtomButton v-for="item in list" :key="item.key" class="btn-sm btn-outline btn-tabs ml-2" :to="item.to">
      <span class="circle w-3 h-3 border border-gray-500 rounded-full mr-[10px]" />
      {{ item.title }}
    </AtomButton>
  </div>
</template>

<script>
import AtomButton from '../../atoms/AtomButton'

export default {
  name: 'OrganismTabs',
  components: {
    AtomButton,
  },
  props: ['list'],
}
</script>

<style lang="postcss" scoped>
.btn-tabs {
  &:hover {
    .circle {
      @apply border-white bg-white;
    }
  }
  &.link-exact-active,
  &.link-active {
    @apply bg-gray-100 text-white;
    .circle {
      @apply border-white bg-white;
    }
  }
}
</style>
