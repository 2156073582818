import { render, staticRenderFns } from "./OrganismTabs.vue?vue&type=template&id=3d537c82&scoped=true&"
import script from "./OrganismTabs.vue?vue&type=script&lang=js&"
export * from "./OrganismTabs.vue?vue&type=script&lang=js&"
import style0 from "./OrganismTabs.vue?vue&type=style&index=0&id=3d537c82&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d537c82",
  null
  
)

export default component.exports