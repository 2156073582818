<template>
  <section class="min-h-[83svh] md:min-h-[100svh] flex pt-[56px] md:py-28 print:min-h-0 print:inline-block print:!pt-0">
    <div v-if="$device.isDesktop || !hero.imageMobile || hero.preferVideo">
      <AtomImage
        v-if="hasImage && hero.variant === 'transparent-fixed' && !hero.preferVideo"
        class="absolute inset-0 object-cover object-center min-h-full w-full hero-shadow"
        :src="hero.image"
        :strapi-image-object="hero.strapiImageObject"
        :strapi-prefer-original="$device.isDesktop"
      />
      <AtomVideo
        v-else-if="($device.isDesktop || hero.preferVideo) && hero.backgroundVideo && hero.backgroundVideo.url"
        :src="hero.backgroundVideo.url"
        class="absolute inset-y-0 min-h-full left-[75px] right-0 object-cover w-full hero-shadow"
        :class="{
          '!left-0': hero.variant === 'transparent-parallax',
        }"
      />
      <AtomImageParallax
        v-else-if="hasImage"
        :src="hero.image"
        :strapi-image-object="hero.strapiImageObject"
        :strapi-prefer-original="$device.isDesktop"
        :img-wrap-classes="hero.imageWrap"
        :img-classes="`w-full object-cover object-center scale-100 ${hero.imagePosition}`"
        class="absolute inset-0 min-h-full border-b-[2px] border-gray-100 hero-shadow"
        :class="{
          'md:pl-[75px]': hero.variant === 'dark-parallax' || hero.variant === 'white-parallax',
        }"
      />
    </div>
    <AtomImageParallax
      v-else-if="hero.imageMobile"
      :src="hero.imageMobile"
      img-classes="w-full object-cover object-center scale-100"
      class="absolute inset-0 min-h-full border-b-[2px] border-gray-100 hero-shadow"
    />
    <div class="container md:py-8 flex md:items-start print:!pl-0">
      <div class="flex flex-col w-full">
        <div class="flex flex-col relative">
          <div class="grid md:grid-cols-12 md:pb-2">
            <div class="hidden md:block pr-3 md:col-span-2">
              <AtomBreadcrumb
                v-for="breadcrumb in hero.breadcrumbs"
                :key="breadcrumb.key"
                :to="breadcrumb.to"
                :content="breadcrumb.title"
                :class="{ '!text-white': !isDark }"
              />
            </div>
            <div class="col-span-10">
              <AtomTitle
                :class="{ '!text-white': !isDark }"
                class-name="print:!text-[14px]"
                :content="hero.title1"
                animation="rotation"
              />
            </div>
          </div>
          <AtomHR class="z-[1] w-full hidden md:block print:!hidden" :class="{ '!border-white': !isDark }" />
          <div class="grid md:grid-cols-12 pt-[2px] md:pt-5 print:!hidden">
            <div class="md:col-span-10 md:col-start-3">
              <div class="md:grid grid-cols-10 justify-between">
                <AtomTitle
                  :class="{ '!col-span-7': hero.desc, '!text-white': !isDark }"
                  class="col-span-10"
                  :content="hero.title2"
                  animation="rotation"
                />
                <AtomHR class="md:hidden w-full mt-2" :class="{ '!border-white': !isDark }" />
                <AtomText
                  v-if="hero.desc"
                  class="mt-3 md:mt-0 col-span-3"
                  :class="{ '!text-white': !isDark, '-md:hidden': hero.hideDesc }"
                  :content="hero.desc"
                  animation="fade"
                />
              </div>
              <slot name="play"></slot>
            </div>
          </div>
          <slot name="footer-title"></slot>
        </div>
        <slot name="footer"></slot>
      </div>
    </div>
  </section>
</template>

<script>
import AtomHR from '../../atoms/AtomHR'
import AtomImage from '../../atoms/AtomImage'
import AtomImageParallax from '../../atoms/AtomImageParallax'
import AtomText from '../../atoms/AtomText'
import AtomTitle from '../../atoms/AtomTitle'
import AtomVideo from '../../atoms/AtomVideo'
import AtomBreadcrumb from '../../atoms/layouts/AtomBreadcrumb'

export default {
  name: 'OrganismHero',
  components: {
    AtomHR,
    AtomTitle,
    AtomText,
    AtomImage,
    AtomVideo,
    AtomImageParallax,
    AtomBreadcrumb,
  },
  props: ['hero'],
  computed: {
    isDark() {
      return !this.hero.variant || this.hero.variant === 'dark-parallax'
    },
    hasImage() {
      return Boolean(this.hero.image || this.hero.strapiImageObject)
    },
  },
}
</script>

<style lang="postcss" scoped></style>
